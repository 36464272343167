import { Filter } from './filter';
import { ReferenceType } from '../_enums/referenceType';
import { expandCamelCase } from '../_helpers/camelCaseHelper';
export class WayFinderTile {
    name: string;
    url: string;
    thumbnailUrl: string;
    id: string;
    parentId: string = null;
    referenceType: ReferenceType;
    selected: boolean;
    itemCount: number;
    allDocumentsUrl: string;
    defaultImage: boolean = false;
    detailUrl: string;
    icon: string;
    groupName: string;
    quantity: number;
    type: string;
    constructor(f: Filter = null) {
        if (f != null) {
            this.name = f.displayText;
            this.id = f.id;
            this.defaultImage = true;
            this.parentId = f.parentId;
            this.referenceType = f.referenceType;
            this.selected = false;
            this.itemCount = f.itemCount;
            this.setThumb(f.thumbnailUrl);
        }
    }
    setThumb(url: string): void {
        this.thumbnailUrl = url;
        this.defaultImage = url == null || url === undefined || url.trim() == '';
    }
}

export class WayFinderTileGroup {
    referenceType: ReferenceType;
    tiles: WayFinderTile[];
    title: string;
    recordCount: number;
    allDocumentsUrl: string;

    constructor(refType: ReferenceType) {
        this.referenceType = refType;
        this.tiles = [];
        this.title = expandCamelCase(ReferenceType[refType]);
        this.recordCount = 0;
    }

    public addTile(tile: WayFinderTile): void {
        this.tiles[this.tiles.length] = tile;
    }
}

