//angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//local imports
import { ReferenceType } from '../_enums/referenceType';
import { AppConfig } from '../app-config';
import { WayFinderProperty } from '../_interfaces/wayFinderProperty';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  constructor(private http: HttpClient, private applicationConfig: AppConfig) {    
  }

    getProperties(refType: ReferenceType, id: string, deptId: string = null) {
        return this.http.post<WayFinderProperty[]>(`${this.applicationConfig.applicationUri}/v2/property/getproperties`, { referenceType: refType, id: id, departmentId: deptId });
    }
}
