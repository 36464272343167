//angular
import { Injectable, OnDestroy } from "@angular/core";

//msal
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";

//rxjs
import { ReplaySubject, Subject, Subscription, filter, takeUntil, lastValueFrom } from "rxjs";

//local imports
import { Messenger } from "./messenger";
import { SignalRConnection } from "./signalr.connection";
import { Security } from "../security";
import { AppConfig } from "../app-config";

/**
 * @summary A service that allows components to communicate with each other
 * @description This service uses signals to allow components to communicate with each other.
 */
@Injectable({
    providedIn: 'root'
})
export class CommunicationService implements OnDestroy {

    private readonly _destroying$ = new Subject<void>();

    private signalrConnection?: SignalRConnection;
    private authSubscription: Subscription;
    private token$ = new ReplaySubject<string>(1);
    private tokenSubscription?: Subscription;

    constructor(
      private msalBroadcastService: MsalBroadcastService,
      private msalService: MsalService,
      private messenger: Messenger,
      private security: Security,
      private appConfig: AppConfig,
    ) { }

    /**
     * @summary Connects to the signalr hub
     * @description This method nd subscribes to the token$ observable to get the access token
     * Connects to connects to the signalr hub and subscribes to notifications from the hub and relays them into the Messenger
     * @param url The url to connect to
     */
    public initialize() {

        this.signalrConnection = new SignalRConnection();

        let getToken = async () =>
        {
            let protectedResources = this.security.getProtectedResources();
            
            let scopes = protectedResources.applicationApi.scopes.read;
            try {

                let silentResult = await lastValueFrom(this.msalService.acquireTokenSilent({ scopes: scopes }));
                return silentResult.accessToken;
            }
            catch(error)
            {
//                this.messenger.send(new UserLoggedOutMessage());                
                return "";
            }
        };

        //TODO:DIW:Use messenger to receive current user
        // Subscribe to the msal notifications to get the access token
        this.authSubscription = this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe(async (result: EventMessage) => {
                // Casting payload as AuthenticationResult to access account
                let authenticationResult = result.payload as AuthenticationResult;
                this.token$.next(authenticationResult.accessToken);

            });

        this.tokenSubscription = this.token$
            .pipe(takeUntil(this._destroying$))
            .subscribe(token => {

                this.signalrConnection!.connect(
                    `${this.appConfig.applicationUri}/v2/broadcast`,
                    getToken,
                    "broadcastmessage",
                    (data: any) => {
                      //Translate the notification into an object, and send it on the message bus

                      let notificationType = this.messenger.getType(data.type);
                      console.log(notificationType);
                      console.log(data);

                        let payload = data.data;
                                                
                        if (!notificationType) 
                        {
                            return;
                        }

                        let notification = Reflect.construct(notificationType, []) as any;

                        if (payload) {
                            Object.assign(notification, payload);
                        }

                        this.messenger.send(notification);

                    });
            });
    }

    async ngOnDestroy(): Promise<void> {
        this._destroying$.next(undefined);

        this.authSubscription.unsubscribe();
        this.tokenSubscription?.unsubscribe();

        await this.signalrConnection?.disconnect();

        this._destroying$.complete();
    }
}