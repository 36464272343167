//angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs imports
import { Observable } from 'rxjs';

//local imports
import { AppConfig } from '../app-config';
import { PowerBiReport } from '../_interfaces/powerBiReport';
import { ExcelExport } from '../_interfaces/excelExport';
import { DownloadUrlResponse } from '../_interfaces/downloadUrlResponse';
import { ReferenceType } from '../_enums/referenceType';
import { Pagesize } from '../_enums/page-size';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
  emptyGuid = '00000000-0000-0000-0000-000000000000';

  constructor(private http: HttpClient, private applicationConfig: AppConfig) {
  }

  downloadReport(id: string): Observable<DownloadUrlResponse > {
    return this.http.get<DownloadUrlResponse>(`${this.applicationConfig.applicationUri}/v2/report/${id}`);
  }

  deleteReport(id: string) {
    return this.http.delete(`${this.applicationConfig.applicationUri}/v2/report/${id}`);
  }

  updateReport(id: string, name: string) {
    let payload: UpdateReportRequest = { name: name };
    return this.http.patch(`${this.applicationConfig.applicationUri}/v2/report/${id}`, payload);
  }

  requestServicesMatrix(id: string | null)  {
    if (id == null) id = this.emptyGuid;
    this.http.post(`${this.applicationConfig.applicationUri}/v2/servicesmatrix/${id}`, {})
      .subscribe(
        response => {
          console.log('HTTP POST request successful', response);
        },
        error => {
          console.log('HTTP POST request failed', error);
        }
      );
  }

  requestPackageExport(projectId: string, roomSystemsMatrix: boolean, roomSummarySheets: boolean, revitModels: boolean, associatedDocuments: boolean) {
    let payload: PackageExportRequest = {
      id: projectId,
      referenceType: ReferenceType.Project,
      pageSize: Pagesize.Letter,
      leftMarginInches: 0.5,
      roomSystemsMatrix: roomSystemsMatrix,
      roomSummarySheets: roomSummarySheets,
      revitModels: revitModels,
      associatedDocuments: associatedDocuments
    };
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/exportpackage`, payload);
  }
}

interface UpdateReportRequest {
  name: string;
}
interface PackageExportRequest {
  id: string;
  referenceType: ReferenceType;
  pageSize: Pagesize;
  leftMarginInches: number;
  roomSystemsMatrix: boolean;
  roomSummarySheets: boolean;
  revitModels: boolean;
  associatedDocuments: boolean;

}
