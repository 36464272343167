//angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs imports
import { BehaviorSubject } from 'rxjs'


//local imports
import { GroupBy } from '../_models/groupBy';
import { PersonaGroupByRel } from '../_models/personaGroupByRel';
import { ReferenceType } from '../_enums/referenceType';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class GroupByService {
  
  groupBysUpdated: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private http: HttpClient, private applicationConfig: AppConfig) {    
  }
      
  getStandardForLibrary() {
    return this.http.get<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/getstandardforlibrary`);
  }
  getStandardForCatalog() {
    return this.http.get<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/getstandardforcatalog`);
  }
  getAllGroupBysForPersonaInLibrary(personaId: string) {
    return this.http.post<PersonaGroupByRel[]>(`${this.applicationConfig.applicationUri}/v2/groupby/getallforpersonainlibrary`, { PersonaId: personaId });  }
  getAllGroupBysForPersonaInCatalog(personaId: string) {
    return this.http.post<PersonaGroupByRel[]>(`${this.applicationConfig.applicationUri}/v2/groupby/getallforpersonaincatalog`, { PersonaId: personaId });
  }  
  getTopLevelGroupBysForPersonaInLibrary(personaId: string) {
    return this.http.post<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/gettoplevelforpersonainlibrary`, { PersonaID: personaId });
  }
  getTopLevelGroupBysForPersonaInCatalog(personaId: string) {
    return this.http.post<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/gettoplevelforpersonaincatalog`, { PersonaID: personaId });
  }
  saveGroupByForLibrary(personaId: string , ids: string) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/groupby/saveforlibrary`, { PersonaID: personaId, GroupByIDs: ids });
  }
  saveGroupByForCatalog(personaId: string, ids: string) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/groupby/saveforcatalog`, { PersonaID: personaId, GroupByIDs: ids });
  }
}
