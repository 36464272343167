import { WayFinderProperty } from '../_interfaces/wayFinderProperty';
import { Property, PropertyCategory, PropertyValue } from '../_interfaces/properties';

export function aggregateProperties(properties: WayFinderProperty[]) {
    let categories: PropertyCategory[] = [];
    let categoryIndices: number[] = [];
    let ind = 0;
    properties.forEach((prop: WayFinderProperty, index: number) => {
      if (prop.categoryName != null) {
        let name = prop.categoryName.toLowerCase();
        if (name.indexOf('space') == -1 && name.indexOf('program') == -1) {
          if (categoryIndices[prop.categoryName] === undefined) {
            categoryIndices[prop.categoryName] = ind++;
            let cat: PropertyCategory = new PropertyCategory(prop.categoryId, prop.categoryName, prop.categoryThumbnail);
            categories.push(cat)
          }
          let category = categories[categoryIndices[prop.categoryName]];
          let property: Property = category.getProperty(prop.propertyName);
          if (property == undefined) {
            property = new Property(prop.propertyId, prop.propertyName);
            category.addProperty(property);
          }
          let value: PropertyValue = new PropertyValue(prop.propertyValueId, prop.propertyValueText, prop.isOverridden);
          property.addValue(value);
        }
      }
    });
    categories.sort((a, b) => a.name.localeCompare(b.name));
    return categories;
}