//angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

//rxjs
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

//msal
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';

//local
import { UrlService } from './_services/url.service';
import { Security } from './security';
import { Messenger } from './_messaging/messenger';
import {
  ReportRequestedMessage,
  ReportReadyMessage,
  PackageExportRequestedMessage,
  PackageExportReadyMessage
} from './_messaging/types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private authSubscription: Subscription;
  private forgotPasswordSubscription: Subscription;

  public title: string = 'Modutecture-Web';

  public previousUrl: string = null;
  public currentUrl: string = null;

  public isIframe: boolean = false;
  public loginDisplay: boolean = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private security: Security,
    private router: Router,
    private urlService: UrlService,
    private messenger: Messenger,
  ) { }

  ngOnInit() {

    this.authService.instance.enableAccountStorageEvents(); // Register the storage listener that will be emitting the events

    this.authSubscription = 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        // Casting payload as AuthenticationResult to access account
        if (result.payload && result.payload['authority'].indexOf('passwordreset') > -1) {
          this.authService.logout();
          return;
        }

        const authenticationResult = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(authenticationResult.account);

      });

    this.forgotPasswordSubscription = 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest = {
            authority: this.security.ResetPasswordAuthority,
            scopes: [],
          };
          this.authService.loginRedirect(resetPasswordFlowRequest);
          return;
        };
      });

    this.HandleNavigation();

    this.messenger.subscribe(this, ReportRequestedMessage, (msg) => { console.log('report requested'); console.log(msg); });
    this.messenger.subscribe(this, ReportReadyMessage, (msg) => { console.log('report requested'); console.log(msg); });
    this.messenger.subscribe(this, PackageExportRequestedMessage, () => { });
    this.messenger.subscribe(this, PackageExportReadyMessage, () => { });
  }

  private HandleNavigation() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);

    this.authSubscription.unsubscribe();
    this.forgotPasswordSubscription.unsubscribe();

    this.messenger.unsubscribeByOwner(this);
    this._destroying$.complete();
  }

}
